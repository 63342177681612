/* MapView.css */
.map-container {
    height: 100vh;
    width: 100%;
    position: relative;
  }
  .leaflet-container {
    width: 100%;
    height: 100vh;
  }
  
  .leaflet-popup-content {
    margin: 0;
    padding: 0;
  }
  
  .leaflet-popup-content-wrapper {
    padding: 0;
  }
  
  .leaflet-popup {
    margin-bottom: 20px;
  }

  .leaflet-bottom {
    display: none !important;
}






.custom-popup {
  min-width: 280px;
  max-width: 350px;
  padding: 0;
}

.popup-header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: linear-gradient(to right, #2563eb, #3b82f6);
  color: white;
  border-radius: 8px 8px 0 0;
  margin: -13px -13px 16px -13px;
}

.popup-number {
  background: rgba(255, 255, 255, 0.2);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
}

.popup-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.popup-image-container {
  position: relative;
  width: calc(100% + 26px);
  margin: -16px -13px 16px -13px;
  height: 200px;
  overflow: hidden;
}

.popup-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup-content {
  padding: 0 8px;
}

.popup-description {
  font-size: 14px;
  line-height: 1.6;
  color: #4b5563;
  margin-bottom: 16px;
}

.popup-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  background: #f8fafc;
  border-radius: 8px;
  margin-bottom: 16px;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #64748b;
}

.detail-item i {
  color: #2563eb;
}

.popup-audio {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.audio-player {
  width: 100%;
  height: 36px;
  border-radius: 18px;
}

.audio-player::-webkit-media-controls-panel {
  background: #f8fafc;
}

.transcript-container {
  background: #f8fafc;
  border-radius: 8px;
  padding: 12px;
}

.transcript-header {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2563eb;
  margin-bottom: 8px;
}

.transcript-text {
  font-size: 13px;
  line-height: 1.6;
  color: #64748b;
  margin: 0;
}

/* Стили для кнопки закрытия */
.leaflet-popup-close-button {
  width: 24px !important;
  height: 24px !important;
  font-size: 16px !important;
  background: white !important;
  border-radius: 50% !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: 8px !important;
  right: 8px !important;
  color: #64748b !important;
}

/* Стили для контейнера popup */
.leaflet-popup-content-wrapper {
  border-radius: 12px !important;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06) !important;
  padding: 0 !important;
}

.leaflet-popup-content {
  margin: 13px !important;
}

.leaflet-popup-tip {
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06) !important;
}

/* Добавьте эти стили в конец MapView.css */

@media (max-width: 768px) {
  .leaflet-popup-content {
    margin: 0 !important;
    width: 85vw !important; /* Ограничиваем ширину попапа */
    max-height: 85vh !important; /* Ограничиваем высоту */
    overflow-y: auto !important; /* Добавляем прокрутку */
  }

  .popup-image-container {
    height: 150px; /* Уменьшаем высоту изображения */
  }

  .popup-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: linear-gradient(to right, #2563eb, #3b82f6);
  }

  .popup-content {
    padding: 12px;
  }

  .popup-description {
    font-size: 13px;
    margin-bottom: 12px;
  }

  .popup-details {
    padding: 10px;
    margin-bottom: 12px;
  }

  .detail-item {
    font-size: 13px;
  }

  .audio-player {
    height: 32px;
  }

  .transcript-container {
    padding: 10px;
  }

  .transcript-text {
    font-size: 12px;
  }

  /* Увеличиваем размер кнопки закрытия для лучшего UX на мобильных */
  .leaflet-popup-close-button {
    width: 32px !important;
    height: 32px !important;
    font-size: 20px !important;
    top: 10px !important;
    right: 10px !important;
    background: rgba(255, 255, 255, 0.9) !important;
    z-index: 2 !important;
  }

  /* Стили для контейнера попапа */
  .leaflet-popup {
    max-width: 90vw !important;
    max-height: 90vh !important;
  }

  .leaflet-popup-content-wrapper {
    max-height: 85vh !important;
    overflow-y: auto !important;
  }

  /* Делаем скроллбар более стильным */
  .leaflet-popup-content-wrapper::-webkit-scrollbar {
    width: 6px;
  }

  .leaflet-popup-content-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .leaflet-popup-content-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  .leaflet-popup-content-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* Для очень маленьких экранов */
@media (max-width: 360px) {
  .leaflet-popup-content {
    width: 80vw !important;
  }

  .popup-header h3 {
    font-size: 16px;
  }

  .popup-number {
    width: 28px;
    height: 28px;
    font-size: 16px;
  }

  .popup-image-container {
    height: 120px;
  }
}