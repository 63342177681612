/* frontend/src/components/HeaderProfile.css */
.header-profile {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .profile-sign-in-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: white;
    border: none;
    border-radius: 24px;
    font-size: 14px;
    color: #1f2937;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
  }
  
  .profile-sign-in-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .profile-avatar-btn {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 6px;
    padding-right: 16px;
    background: white;
    border: none;
    border-radius: 28px;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
  }
  
  .profile-avatar-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .profile-avatar-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-info {
    text-align: left;
  }
  
  .profile-name {
    font-size: 14px;
    font-weight: 500;
    color: #1f2937;
  }
  
  .profile-dropdown {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 280px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    animation: dropdownFade 0.2s ease;
  }
  
  .dropdown-header {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .dropdown-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .dropdown-user-info {
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-name {
    font-size: 16px;
    font-weight: 600;
    color: #1f2937;
  }
  
  .dropdown-email {
    font-size: 12px;
    color: #6b7280;
  }
  
  .dropdown-content {
    padding: 8px;
  }
  
  .dropdown-item {
    width: 100%;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border: none;
    background: none;
    font-size: 14px;
    color: #4b5563;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.2s ease;
  }
  
  .dropdown-item:hover {
    background: #f3f4f6;
    color: #2563eb;
  }
  
  .dropdown-item i {
    font-size: 16px;
    width: 20px;
    text-align: center;
  }
  
  @keyframes dropdownFade {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Медиа-запрос для мобильных устройств */
  @media (max-width: 640px) {
    .profile-info {
      display: none;
    }
  
    .profile-avatar-btn {
      padding: 6px;
    }
  
    .profile-dropdown {
      width: 260px;
    }
  }