/* frontend/src/pages/Home.css */
.home {
  padding-top: 80px;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.country-section {
  margin: 30px 0;
}

.country-title {
  font-size: 2rem;
  color: #222;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.area-section {
  margin: 20px 0;
}

.area-title {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 10px;
}

.place-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.map-link {
  display: inline-block;
  margin-bottom: 20px; /* Space between link and content */
  padding: 10px 20px;
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.map-link:hover {
  background-color: #0056b3; /* Darker shade on hover */
}