/* File: frontend/src/components/SearchBox.css */
.search-box-container {
    position: relative;
    width: 100%;
  }
  
  .search-input {
    width: 100%;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    font-size: 16px;
    background: white;
  }
  
  .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 8px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    max-height: 400px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .search-result-item {
    padding: 12px 16px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.2s;
  }
  
  .search-result-item:hover {
    background-color: #f5f5f5;
  }
  
  .search-result-title {
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .search-result-description {
    font-size: 14px;
    color: #666;
  }