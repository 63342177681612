/* frontend/src/components/LocationCard.css */
.location-card {
    border-radius: 12px;
    overflow: hidden;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s;
  }
  
  .location-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  }
  
  .location-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .location-content {
    padding: 16px;
  }
  
  .location-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #1f2937;
  }
  
  .location-description {
    font-size: 14px;
    color: #6b7280;
    margin-bottom: 12px;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .location-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e5e7eb;
  }
  
  .action-buttons {
    display: flex;
    gap: 8px;
  }
  
  .favorite-btn,
  .view-more-btn {
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.2s;
  }
  
  .favorite-btn {
    background: #f3f4f6;
    color: #374151;
  }
  
  .favorite-btn:hover {
    background: #e5e7eb;
  }
  
  .favorite-btn.active {
    background: #fee2e2;
    color: #dc2626;
  }
  
  .view-more-btn {
    background: #2563eb;
    color: white;
  }
  
  .view-more-btn:hover {
    background: #1d4ed8;
  }
  
  .audio-player {
    width: 100%;
    margin: 12px 0;
    border-radius: 18px;
  }
  
  /* Мобильные стили */
  @media (max-width: 768px) {
    .location-card {
      margin: 16px;
    }
  
    .location-content {
      padding: 20px;
    }
  
    .action-buttons {
      width: 100%;
      justify-content: space-between;
    }
  
    .favorite-btn,
    .view-more-btn {
      flex: 1;
      justify-content: center;
    }
  }