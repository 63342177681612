/* src/styles/App.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: #f7f9fc;
    color: #333;
  }
  
  h1 {
    font-size: 2rem;
    text-align: center;
    margin-top: 20px;
  }
  