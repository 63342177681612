/* frontend/src/pages/Profile.css */
.profile-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 24px;
  }
  
  .profile-info {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .profile-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .credits-info {
    text-align: center;
  }
  
  .credits-amount {
    font-size: 24px;
    font-weight: bold;
    color: #2563eb;
    display: block;
  }
  
  .credits-label {
    font-size: 14px;
    color: #666;
  }
  
  .buy-credits-btn {
    margin-top: 8px;
    padding: 8px 16px;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .profile-tabs {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
  }
  
  .tab {
    padding: 12px 24px;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: 500;
    color: #666;
    border-bottom: 2px solid transparent;
    transition: all 0.2s;
  }
  
  .tab.active {
    color: #2563eb;
    border-bottom-color: #2563eb;
  }
  
  .locations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
  }
  
  @media (max-width: 768px) {
    .profile-header {
      flex-direction: column;
      text-align: center;
      gap: 16px;
    }
  
    .profile-info {
      flex-direction: column;
    }
  
    .profile-tabs {
      justify-content: center;
    }
  }